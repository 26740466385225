<!--@for (nameItem of nameItems; track producerIndex; let producerIndex = $index) {
  <div>Navn i DAMS: {{ nameItem.dams_title }}</div>
  @if (nameItem.kulturnav_id) {
    <div>Kulturnav id funnet, behøver ikke gjøre noen brukervalg: {{ nameItem.kulturnav_id }}</div>
  } @else {
    @if (nameItem.suggestions?.length) {
      Vi fant forslag til eksisterende navn i Primus. Velg en av dem eller velg å opprette nytt navn.
      Bruker midlertidig checkbox for å sette navn, men bør være radiogruppe eller select
      @for (suggestion of nameItem.suggestions; track suggestionIndex; let suggestionIndex = $index) {
        <div class="edit-item__suggestion-box">
                <span class="edit-item__suggestion-label edit-item__suggestion-label--bold">
                  {{ suggestion.name }} type {{ suggestion.person_type }}
                  , samling {{ suggestion.collection_name }}
                  @if (suggestion.identification_number) {
                    , identifikasjonsnummer: {{ suggestion.identification_number }}
                  }
                </span>
          <input type="checkbox" (click)="setSuggestion(nameItem, suggestion)" [disabled]="nameItem.create_new">
        </div>
      }
      @if (nameItem.selected_suggestion) {
        Valgt forslag: {{ nameItem.selected_suggestion.name }}
      }
    } @else {
      Fant ingen forslag!
    }
    <div>
      Trykk her for å generere et nytt navn basert på {{nameItem.dams_title}}
      <input type="checkbox" [(ngModel)]="nameItem.create_new">
    </div>
  }

}-->

@if (nameItems && nameItems.length > 0) {
    @for (nameItem of nameItems; track producerIndex; let producerIndex = $index) {
      <div class="import-name">
        <div class="import-name__content">
          <div class="import-name__name import-name__name--grow import-name__name--bold">
            {{nameItem.dams_title}}
            <span class="import-name__name">
              er oppgitt som produsent i DAMS. Velg om du ønsker å bruke en av de følgende treffene i Primus:
            </span>
          </div>

          <div class="import-name__buttons">
            @if (nameItem.kulturnav_id) {
              <span>
                Navn fra DAMS er KulturNav autoritet og vil bli brukt.
              </span>
            }
            @else if (nameItem.dams_id_exists) {
              <span>
                Navn fra DAMS allerede importert, dette blir gjenbrukt
              </span>
            }
            @else {
              @if (nameItem.suggestions.length === 0) {
                <span>
                  Ingen tilsvarende navn funnet.
                </span>
              }
              <mat-radio-group class="import-name__radio-group" (change)="selectionChanged($event, nameItem)">
                @for (suggestion of nameItem.suggestions; track $index) {
                  <mat-radio-button [value]="suggestion">
                    {{suggestion.name}}
                  </mat-radio-button>
                }
                <mat-radio-button value="create">
                  Opprett som nytt navn: {{nameItem.dams_title}}
                </mat-radio-button>
              </mat-radio-group>
            }
          </div>
        </div>
      </div>
    }
}
