import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DamsNameImportItem, PrimusNameSuggestion} from "../../../core/definitions/dams-import-data";
import {FormsModule} from "@angular/forms";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatRadioButton, MatRadioChange, MatRadioGroup} from "@angular/material/radio";

@Component({
  selector: 'app-operation-view-dams-import-name',
  standalone: true,
  imports: [
    FormsModule,
    MatCheckbox,
    MatIcon,
    MatIconButton,
    MatButton,
    MatSlideToggle,
    MatRadioGroup,
    MatRadioButton
  ],
  templateUrl: './operation-view-dams-import-name.component.html',
  styleUrl: './operation-view-dams-import-name.component.scss'
})
export class OperationViewDamsImportNameComponent {
  @Input() nameItems: DamsNameImportItem[] = [];

  @Output() toggleSearch = new EventEmitter<string>();

  selectionChanged(event: MatRadioChange, item: DamsNameImportItem) {
    if (event.value === 'create') {
      item.create_new = true;
    }
    else {
      item.selected_suggestion = event.value;
    }
  }

  setSuggestion(nameItem: DamsNameImportItem, suggestion: PrimusNameSuggestion) {
    nameItem.selected_suggestion = suggestion;
  }
}
